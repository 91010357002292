@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-afmf {
    background-image: url("/src/img/logo-bg.png");
}

@media (max-width: 767px) {

    #mobileMenu {
        position: absolute;
        top: 0;
        right: 0;
        height: 100vh;
        z-index: 20;
        width: 100%;
        background: rgb(0 0 0 / 75%);
    }
    #mobileMenu > div {
        background: #F3F4F6;
        width: 298px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100%;
        margin-left: auto;
    }

}
