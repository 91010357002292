*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.left-1\/2 {
  left: 50%;
}

.top-1\/3 {
  top: 33.3333%;
}

.right-4 {
  right: 1rem;
}

.top-4 {
  top: 1rem;
}

.z-30 {
  z-index: 30;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.-mt-\[70px\] {
  margin-top: -70px;
}

.-mt-\[78px\] {
  margin-top: -78px;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mt-12 {
  margin-top: 3rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.-ml-\[40\%\] {
  margin-left: -40%;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.h-\[442px\] {
  height: 442px;
}

.h-80 {
  height: 20rem;
}

.h-6 {
  height: 1.5rem;
}

.h-full {
  height: 100%;
}

.w-20 {
  width: 5rem;
}

.w-\[129px\] {
  width: 129px;
}

.w-full {
  width: 100%;
}

.w-6 {
  width: 1.5rem;
}

.w-4 {
  width: 1rem;
}

.w-3 {
  width: .75rem;
}

.w-4\/5 {
  width: 80%;
}

.w-14 {
  width: 3.5rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-\[620px\] {
  max-width: 620px;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-\[182px\] {
  max-width: 182px;
}

.flex-none {
  flex: none;
}

.list-disc {
  list-style-type: disc;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-8 {
  gap: 2rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-2 {
  gap: .5rem;
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.\!overflow-visible {
  overflow: visible !important;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-tl-3xl {
  border-top-left-radius: 1.5rem;
}

.rounded-br-3xl {
  border-bottom-right-radius: 1.5rem;
}

.rounded-bl-\[72px\] {
  border-bottom-left-radius: 72px;
}

.rounded-tr-\[72px\] {
  border-top-right-radius: 72px;
}

.rounded-br-\[100px\] {
  border-bottom-right-radius: 100px;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-\[\#1E2023\] {
  --tw-border-opacity: 1;
  border-color: rgb(30 32 35 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-\[\#F3F4F6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-\[\#4EC200\] {
  --tw-bg-opacity: 1;
  background-color: rgb(78 194 0 / var(--tw-bg-opacity));
}

.bg-\[\#1E2023\] {
  --tw-bg-opacity: 1;
  background-color: rgb(30 32 35 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-contain {
  background-size: contain;
}

.bg-left-bottom {
  background-position: 0 100%;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.object-cover {
  object-fit: cover;
}

.p-2 {
  padding: .5rem;
}

.p-6 {
  padding: 1.5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.leading-none {
  line-height: 1;
}

.leading-\[18px\] {
  line-height: 18px;
}

.text-\[\#1E2023\] {
  --tw-text-opacity: 1;
  color: rgb(30 32 35 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-\[\#065330\] {
  --tw-text-opacity: 1;
  color: rgb(6 83 48 / var(--tw-text-opacity));
}

.text-\[\#F3F4F6\] {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-\[\#FF7F01\] {
  --tw-text-opacity: 1;
  color: rgb(255 127 1 / var(--tw-text-opacity));
}

.text-\[\#4EC200\] {
  --tw-text-opacity: 1;
  color: rgb(78 194 0 / var(--tw-text-opacity));
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-transparent {
  --tw-ring-color: transparent;
}

.\!ring-red-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity)) !important;
}

.ring-offset-1 {
  --tw-ring-offset-width: 1px;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.bg-afmf {
  background-image: url("logo-bg.21f87fbb.png");
}

@media (max-width: 767px) {
  #mobileMenu {
    height: 100vh;
    z-index: 20;
    width: 100%;
    background: #000000bf;
    position: absolute;
    top: 0;
    right: 0;
  }

  #mobileMenu > div {
    width: 298px;
    text-align: center;
    height: 100%;
    background: #f3f4f6;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    display: flex;
  }
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:left-0:after {
  content: var(--tw-content);
  left: 0;
}

.after\:bottom-0:after {
  content: var(--tw-content);
  bottom: 0;
}

.after\:left-1\/2:after {
  content: var(--tw-content);
  left: 50%;
}

.after\:-ml-24:after {
  content: var(--tw-content);
  margin-left: -6rem;
}

.after\:h-0\.5:after {
  content: var(--tw-content);
  height: .125rem;
}

.after\:h-0:after {
  content: var(--tw-content);
  height: 0;
}

.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:w-48:after {
  content: var(--tw-content);
  width: 12rem;
}

.after\:max-w-\[289px\]:after {
  content: var(--tw-content);
  max-width: 289px;
}

.after\:rounded-md:after {
  content: var(--tw-content);
  border-radius: .375rem;
}

.after\:bg-\[\#4EC200\]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(78 194 0 / var(--tw-bg-opacity));
}

.after\:bg-\[\#1E2023\]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(30 32 35 / var(--tw-bg-opacity));
}

.after\:bg-\[\#FF7F01\]:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 127 1 / var(--tw-bg-opacity));
}

.after\:opacity-0:after {
  content: var(--tw-content);
  opacity: 0;
}

.hover\:after\:opacity-100:hover:after {
  content: var(--tw-content);
  opacity: 1;
}

@media (min-width: 640px) {
  .sm\:-ml-48 {
    margin-left: -12rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:w-96 {
    width: 24rem;
  }

  .sm\:\!max-w-xs {
    max-width: 20rem !important;
  }

  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

@media (min-width: 768px) {
  .md\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .md\:mt-6 {
    margin-top: 1.5rem;
  }

  .md\:mr-20 {
    margin-right: 5rem;
  }

  .md\:-mt-\[144px\] {
    margin-top: -144px;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:-mt-\[178px\] {
    margin-top: -178px;
  }

  .md\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .md\:mt-24 {
    margin-top: 6rem;
  }

  .md\:mt-12 {
    margin-top: 3rem;
  }

  .md\:mt-7 {
    margin-top: 1.75rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:block {
    display: block;
  }

  .md\:\!grid {
    display: grid !important;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:h-\[395px\] {
    height: 395px;
  }

  .md\:w-36 {
    width: 9rem;
  }

  .md\:w-\[216px\] {
    width: 216px;
  }

  .md\:w-14 {
    width: 3.5rem;
  }

  .md\:\!w-full {
    width: 100% !important;
  }

  .md\:w-7 {
    width: 1.75rem;
  }

  .md\:w-5 {
    width: 1.25rem;
  }

  .md\:\!max-w-full {
    max-width: 100% !important;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:gap-12 {
    gap: 3rem;
  }

  .md\:\!gap-12 {
    gap: 3rem !important;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-x-14 {
    column-gap: 3.5rem;
  }

  .md\:\!overflow-hidden {
    overflow: hidden !important;
  }

  .md\:rounded-bl-\[112px\] {
    border-bottom-left-radius: 112px;
  }

  .md\:rounded-tr-\[112px\] {
    border-top-right-radius: 112px;
  }

  .md\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .md\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .md\:p-3 {
    padding: .75rem;
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .md\:pt-20 {
    padding-top: 5rem;
  }

  .md\:pb-24 {
    padding-bottom: 6rem;
  }

  .md\:pb-16 {
    padding-bottom: 4rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:pb-20 {
    padding-bottom: 5rem;
  }

  .md\:pb-8 {
    padding-bottom: 2rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:leading-\[18px\] {
    line-height: 18px;
  }

  .md\:leading-6 {
    line-height: 1.5rem;
  }

  .after\:md\:-ml-36:after {
    content: var(--tw-content);
    margin-left: -9rem;
  }

  .after\:md\:h-1\.5:after {
    content: var(--tw-content);
    height: .375rem;
  }

  .after\:md\:h-1:after {
    content: var(--tw-content);
    height: .25rem;
  }

  .after\:md\:w-72:after {
    content: var(--tw-content);
    width: 18rem;
  }
}

@media (min-width: 1024px) {
  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:mr-10 {
    margin-right: 2.5rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-80 {
    width: 20rem;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)) );
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-br-\[112px\] {
    border-bottom-right-radius: 112px;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg\:pb-44 {
    padding-bottom: 11rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-\[54px\] {
    font-size: 54px;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:leading-\[65px\] {
    line-height: 65px;
  }
}

@media (min-width: 1280px) {
  .xl\:ml-16 {
    margin-left: 4rem;
  }

  .xl\:bg-auto {
    background-size: auto;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
}

/*# sourceMappingURL=index.a19efe46.css.map */
